import OnboardingAlert from '../views/Onboarding/Components/OnboardingAlert.vue'
import OnboardingCodeblock from '../views/Onboarding/Components/OnboardingCodeblock.vue'
import OnboardingCollapse from '../views/Onboarding/Components/OnboardingCollapse.vue'
import OnboardingInput from '../views/Onboarding/Components/OnboardingInput.vue'
import OnboardingRichtext from '../views/Onboarding/Components/OnboardingRichtext.vue'

export default {
    install(Vue, options) {
        Vue.component('onboardingAlert', OnboardingAlert)
        Vue.component('onboardingCodeblock', OnboardingCodeblock)
        Vue.component('onboardingCollapse', OnboardingCollapse)
        Vue.component('onboardingInput', OnboardingInput)
        Vue.component('onboardingRichtext', OnboardingRichtext)
    },
}
