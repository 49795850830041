<template>
    <div class="url-container">
        <div
            class="url-btn url-btn-left pointer-action"
            v-b-tooltip.hover
            title="Copy to Clipboard"
            @click="copyToClipboard"
        >
            <i class="fa fa-copy" />
        </div>
        <b-form-input v-model="value" type="text" class="url-value pointer-disabled"></b-form-input>
        <a
            class="url-btn url-btn-right pointer-action"
            v-b-tooltip.hover
            title="Open URL"
            :href="value"
            target="_blank"
        >
            <i class="fa fa-chain" />
        </a>
    </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
    props: {
        value: {
            type: String,
        },
    },
    methods: {
        copyToClipboard() {
            this.$copyText(this.value)
        },
    },
}
</script>

<style lang="scss">
.url-container {
    .url-btn {
        display: inline-block;
        width: 40px;
        font-size: 0.875rem;
        height: calc(1.5em + 0.75rem + 2px);
        background-color: #ececec6e;
        background-clip: padding-box;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        border: 1px solid #e8e8e8;
        color: #151b1e;
    }
    .url-btn:hover i {
        font-weight: 600;
        color: #151b1e;
    }
    .url-btn-left {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: none;
    }
    .url-btn-right {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: none;
    }
    .url-value {
        display: inline-block;
        width: calc(100% - 80px);
        border-radius: 0 !important;
        border-left: none !important;
        border-right: none !important;
    }
}
</style>
