export const currencies = [
    {code: 'USD', name: 'US Dollars'},
    {code: 'AED', name: 'United Arab Emirates Dirham'},
    {code: 'ARS', name: 'Argentine Pesos'},
    {code: 'AUD', name: 'Australian Dollars'},
    {code: 'BGN', name: 'Bulgarian Lev'},
    {code: 'BOB', name: 'Bolivian Boliviano'},
    {code: 'BRL', name: 'Brazilian Real'},
    {code: 'CAD', name: 'Canadian Dollars'},
    {code: 'CHF', name: 'Swiss Francs'},
    {code: 'CLP', name: 'Chilean Peso'},
    {code: 'CNY', name: 'Yuan Renminbi'},
    {code: 'COP', name: 'Colombian Peso'},
    {code: 'CZK', name: 'Czech Koruna'},
    {code: 'DKK', name: 'Denmark Kroner'},
    {code: 'EGP', name: 'Egyptian Pound'},
    {code: 'EUR', name: 'Euros'},
    {code: 'FRF', name: 'French Francs'},
    {code: 'GBP', name: 'British Pounds'},
    {code: 'HKD', name: 'Hong Kong Dollars'},
    {code: 'HRK', name: 'Croatian Kuna'},
    {code: 'HUF', name: 'Hungarian Forint'},
    {code: 'IDR', name: 'Indonesian Rupiah'},
    {code: 'ILS', name: 'Israeli Shekel'},
    {code: 'INR', name: 'Indian Rupee'},
    {code: 'JPY', name: 'Japanese Yen'},
    {code: 'KRW', name: 'South Korean Won'},
    {code: 'LTL', name: 'Lithuanian Litas'},
    {code: 'MAD', name: 'Moroccan Dirham'},
    {code: 'MXN', name: 'Mexican Peso'},
    {code: 'MYR', name: 'Malaysian Ringgit'},
    {code: 'NOK', name: 'Norway Kroner'},
    {code: 'NZD', name: 'New Zealand Dollars'},
    {code: 'PEN', name: 'Peruvian Nuevo Sol'},
    {code: 'PHP', name: 'Philippine Peso'},
    {code: 'PKR', name: 'Pakistan Rupee'},
    {code: 'PLN', name: 'Polish New Zloty'},
    {code: 'RON', name: 'New Romanian Leu'},
    {code: 'RSD', name: 'Serbian Dinar'},
    {code: 'RUB', name: 'Russian Ruble'},
    {code: 'SAR', name: 'Saudi Riyal'},
    {code: 'SEK', name: 'Sweden Kronor'},
    {code: 'SGD', name: 'Singapore Dollars'},
    {code: 'THB', name: 'Thai Baht'},
    {code: 'TRY', name: 'Turkish Lira'},
    {code: 'TWD', name: 'New Taiwan Dollar'},
    {code: 'UAH', name: 'Ukrainian Hryvnia'},
    {code: 'VEF', name: 'Venezuela Bolivar Fuerte'},
    {code: 'VND', name: 'Vietnamese Dong'},
    {code: 'ZAR', name: 'South African Rand'},
]
