<template>
    <div class="answer-builder">
        <template v-if="disableAdvanced">
            <answer
                v-for="variable in variables"
                :key="variable.key"
                v-model="variables[variable.idx]"
                :valid.sync="variableValid[variable.key]"
                :question="questions[variable.idx]"
                :custom-queries="customQueries"
                :resource-options="resourceOptions"
                :project="project"
                @input="emitInput"
                @update:valid="emitUpdate"
            />
        </template>
        <template v-else>
            <answer
                v-for="variable in notAdvancedVariables"
                :key="variable.key"
                v-model="variables[variable.idx]"
                :valid.sync="variableValid[variable.key]"
                :question="questions[variable.idx]"
                :custom-queries="customQueries"
                :resource-options="resourceOptions"
                :project="project"
                @input="emitInput"
                @update:valid="emitUpdate"
            />
        </template>
        <p
            v-if="!disableAdvanced && advancedVariables.length"
            :class="{
                'm-1': true,
                'collapse-section-button': true,
                'arrow-right': showAdvanced,
                'arrow-down': !showAdvanced,
            }"
            @click="showAdvanced = !showAdvanced"
        >
            Advanced options
        </p>
        <b-collapse v-model="showAdvanced">
            <answer
                v-for="variable in advancedVariables"
                :key="variable.key"
                v-model="variables[variable.idx]"
                :valid.sync="variableValid[variable.key]"
                :question="questions[variable.idx]"
                :custom-queries="customQueries"
                :resource-options="resourceOptions"
                :project="project"
                @input="emitInput"
                @update:valid="emitUpdate"
            />
        </b-collapse>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {mapGetters} from 'vuex'

import Answer from './Answer.vue'

import {currencies} from '@/shared/currencies'
import {fetchAll} from '@/shared/fetchAll'

const d3format = require('d3-time-format')

export default {
    props: {
        value: {
            required: true,
        },
        questions: {
            required: true,
            type: Array,
        },
        project: {
            required: true,
        },
        valid: {
            type: Boolean,
        },
        disableAdvanced: {
            type: Boolean,
        },
        relayCustomQueries: {
            type: Array,
            default: null,
        },
        relayResourceOptions: {
            type: Array,
            default: null,
        },
        silent: {
            type: Boolean,
        },
    },
    components: {
        Answer,
    },
    mixins: [validationMixin],
    validations: {
        variables: {
            $each: {
                valid: function (variable) {
                    return variable && variable.key ? Boolean(this.variableValid[variable.key]) : false
                },
            },
        },
    },
    data() {
        return {
            variables: [],
            variableValid: {},
            customQueries: [],
            showAdvanced: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            collect: 'collect/all',
            process: 'process/all',
            report: 'report/all',
        }),
        resourceOptions() {
            return (
                this.relayResourceOptions || [
                    ...this.collect.map((el) => ({resource: 'collect', ...el})),
                    ...this.process.map((el) => ({resource: 'process', ...el})),
                    ...this.report.map((el) => ({resource: 'report', ...el})),
                ]
            )
        },
        notAdvancedVariables() {
            return this.variables.filter((el) => !el.isAdvanced)
        },
        advancedVariables() {
            return this.variables.filter((el) => el.isAdvanced)
        },
    },
    watch: {
        async questions() {
            await fetchAll(this.$store, this.project.id)
            this.buildVariables()
        },

        async project() {
            await fetchAll(this.$store, this.project.id)
            this.buildVariables()
        },
    },
    async created() {
        !this.silent && this.$store.commit('loading/PROCESSING', `Loading answers...`)

        const promises = []

        if (!this.relayCustomQueries) {
            promises.push(this.fetchCustomQueries())
        } else {
            this.customQueries = this.relayCustomQueries
        }

        if (!this.relayResourceOptions) {
            promises.push(fetchAll(this.$store, this.project.id))
        }

        if (promises.length) {
            await Promise.all(promises)
        }

        this.buildVariables()
        this.emitInput()
        this.$nextTick(() => this.emitLoad())

        !this.silent && this.$store.commit('loading/PROCESSED')
    },
    methods: {
        async fetchCustomQueries() {
            try {
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/customQuery/project/${this.project.id}`
                )

                this.customQueries = response.data.data
            } catch (exception) {
                this.customQueries = []
            }
        },

        emitInput() {
            this.$emit('input', this.stripVariables())
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', this.$v ? !this.$v.$invalid : false)
        },

        emitLoad() {
            this.$emit('load')
        },

        buildVariables() {
            this.variables = this.questions.map((question, index) => {
                let variable = {
                    key: question.variableName,
                    value: question.defaultValue && !question.multiple ? question.defaultValue : null,
                    values: question.defaultValue && question.multiple ? question.defaultValue : [],
                    variables: [],
                    isAdvanced: question.isAdvanced,
                    idx: index,
                }

                if (this.value) {
                    const inputVariable = this.value.find((el) => el.key === question.variableName)

                    if (inputVariable) {
                        variable.value = inputVariable.value
                    }

                    if (inputVariable && question.type === 'LIST') {
                        if (question.multiple) {
                            variable.values = inputVariable.values
                                .map((val) => {
                                    const optionFound = question.choices.find((el) => el.value === val)
                                    return optionFound ? optionFound.label : null
                                })
                                .filter(Boolean)
                        } else {
                            variable.value = question.choices.find(
                                (el) =>
                                    el.value === inputVariable.value ||
                                    ([null, ''].includes(el.value) && [null, ''].includes(inputVariable.value))
                            )
                        }

                        variable.variables = inputVariable.variables || []
                    }

                    if (inputVariable && question.type === 'RESOURCE') {
                        if (question.multiple) {
                            variable.values = inputVariable.values
                                .map((val) => {
                                    const optionFound = this.resourceOptions.find(
                                        (el) => el && val && el.id === val.id && el.resource === val.type
                                    )
                                    return optionFound ? optionFound.id : null
                                })
                                .filter(Boolean)
                        } else {
                            const optionFound = this.resourceOptions.find(
                                (el) =>
                                    inputVariable &&
                                    inputVariable.value &&
                                    inputVariable.value.id &&
                                    el.id === inputVariable.value.id &&
                                    el.resource === inputVariable.value.type
                            )

                            if (optionFound) {
                                variable.value = {
                                    id: optionFound.id,
                                    label: optionFound.name,
                                    subLabel: optionFound.subtype,
                                }
                            }
                        }
                    }

                    if (inputVariable && question.type === 'CURRENCY') {
                        const optionFound = currencies.find(
                            (el) => inputVariable && inputVariable.value && el.code === inputVariable.value
                        )

                        if (optionFound) {
                            variable.value = {
                                id: optionFound.code,
                                label: `${optionFound.code} (${optionFound.name})`,
                            }
                        }
                    }

                    if (inputVariable && question.type === 'TIMEZONE') {
                        variable.value = {
                            id: inputVariable.value,
                            label: inputVariable.value,
                        }
                    }

                    if (inputVariable && question.type === 'DATE') {
                        variable.value = d3format.timeFormat('%Y-%m-%d')(new Date(inputVariable.value))
                    }
                }

                return variable
            })
        },

        stripVariables() {
            return this.variables.map((variable) => {
                const question = this.questions.find((question) => question.variableName === variable.key)

                if (question && question.type === 'LIST') {
                    const {key, value, values, nestedQuestions, variables} = variable

                    let _value = value ? value.value : null
                    let _values = values ? values.map((el) => el.value) : []

                    let _variable = {key}

                    if (question.multiple) {
                        _variable.values = _values
                    } else {
                        _variable.value = _value
                    }

                    if (value && value.name) {
                        _variable.name = value.name
                    }

                    if (nestedQuestions && nestedQuestions.length > 0) {
                        _variable.variables = variables
                    }

                    return _variable
                }

                if (question && question.type === 'CURRENCY') {
                    const {key, value} = variable

                    let _value = value ? value.id : null
                    let _variable = {key, value: _value}

                    return _variable
                }

                if (question && question.type === 'TIMEZONE') {
                    const {key, value} = variable

                    let _value = value ? value.id : null
                    let _variable = {key, value: _value}

                    return _variable
                }

                if (question && question.type === 'RESOURCE') {
                    const {key, value, values} = variable

                    let _value =
                        value && this.resourceOptions
                            ? this.resourceOptions.find((option) => option.id === value.id)
                            : null
                    let _values =
                        values && this.resourceOptions
                            ? values.map((el) => this.resourceOptions.find((option) => option.id === el))
                            : []

                    let _variable = {key}

                    if (question.multiple) {
                        _variable.values = _values.filter(Boolean).map((el) => {
                            const {id, resource: type} = el
                            return {id, type}
                        })
                    } else {
                        if (_value) {
                            const {id, resource: type} = _value
                            _variable.value = {id, type}
                        }
                    }

                    return _variable
                }

                if (question && question.type === 'DATE') {
                    const {key, value} = variable
                    return {key, value: value ? new Date(value) : null}
                }

                const {key, value} = variable
                return {key, value}
            })
        },
    },
}
</script>

<style></style>
