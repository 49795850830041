import Vue from 'vue'
import onboarding from '@/plugins/onboarding'
Vue.use(onboarding)

import {mapGetters} from 'vuex'

export const onboardingMixin = {
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        variables() {
            return {
                projectId: this.activeProject.id,
                lowercaseProjectId: this.activeProject.id.toLowerCase(),
            }
        },
    },
    methods: {
        substituteVariables(text) {
            return Object.keys(this.variables).reduce((acc, key) => {
                return acc.replace(new RegExp(`{{${key}}}`, 'g'), this.variables[key])
            }, text)
        },
    },
}
