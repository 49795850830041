<template>
    <div class="animated fadeIn">
        <template v-if="story && story.content">
            <component
                v-for="blokComponent in story.content.aboveForm"
                :key="blokComponent._uid"
                :blok="blokComponent"
                :is="blokComponent.component"
                :open="true"
                :substituteVariables="substituteVariables"
            ></component>
        </template>

        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerText="Setup Your Report"
            ref="tabbedForm"
            @continue="continueForm"
            @reset="resetForm"
            @finish="onFinish"
            @input="onFormInput"
            :details="isDetails"
            :dirty="updateDirty"
            :fetched="fetched"
            :update="updateDataProvider"
        >
            <template v-slot:step-1-input-storyblok-step-1 v-if="story && story.content">
                <component
                    v-for="blokComponent in story.content.firstPage"
                    :key="blokComponent._uid"
                    :blok="blokComponent"
                    :is="blokComponent.component"
                    :open="true"
                    :substituteVariables="substituteVariables"
                ></component>
            </template>

            <template v-slot:step-1-input-report-version-right-col>
                <b-btn
                    v-if="latestMinor !== version"
                    variant="primary"
                    v-b-tooltip.hover
                    title="You can safely upgrade to a newer version"
                    class="wit-highlight"
                    @click="showUpgradeModal = true"
                    >Upgrade</b-btn
                >
            </template>

            <template v-slot:step-1-input-answers>
                <answer-builder
                    ref="answerBuilder"
                    v-model="$v.reportForm.variables.$model"
                    :questions="questions"
                    :valid.sync="answersValid"
                    :project="activeProject"
                    :relay-custom-queries="versions"
                    :silent="true"
                    @load="onLoad"
                />
            </template>

            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="reportError"
                            :state="reportError.state"
                            :invalid="reportError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>

            <template v-slot:[createStepSlot]="{step}" v-if="!isDetails">
                <b-row>
                    <b-col>
                        <Feedback
                            :state="step.checked ? !step.invalid : null"
                            invalid="You need to finish this step before accessing the next one"
                            valid="This step is complete"
                        ></Feedback>
                        <b-button
                            id="create-provider-button"
                            @click="() => createDataProvider(step)"
                            :disabled="step.invalid"
                            variant="primary"
                        >
                            <i class="icon-check"></i>
                            <span>Create</span>
                        </b-button>
                    </b-col>
                </b-row>
            </template>

            <template v-slot:[lastStepSlot]>
                <template v-if="story && story.content">
                    <component
                        v-for="blokComponent in story.content.secondPage"
                        :key="blokComponent._uid"
                        :blok="blokComponent"
                        :is="blokComponent.component"
                        :open="true"
                        :substituteVariables="substituteVariables"
                    ></component>
                </template>

                <table class="data-provider__url-table">
                    <tbody>
                        <template v-if="dataStudioTemplates && dataStudioTemplates.length">
                            <tr>
                                <td
                                    colspan="2"
                                    class="data-provider__url-table-header data-provider__url-table-header--data-studio"
                                >
                                    Google Data Studio Templates
                                </td>
                            </tr>
                            <tr v-for="(template, index) in dataStudioTemplates">
                                <td>
                                    <span>{{ template.name }}</span>
                                </td>

                                <td>
                                    <a :href="dataStudioUrls[index]" target="_blank">
                                        <b-button variant="warning">
                                            <img
                                                width="20"
                                                height="20"
                                                src="@/assets/gcp-icons/google-data-studio.svg"
                                            />
                                            Link to template
                                            <i class="fa fa-external-link-square" />
                                        </b-button>
                                    </a>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
        </wit-tabbed-form>

        <upgrade-modal
            v-if="isDetails && latestMinor !== version"
            v-model="showUpgradeModal"
            :upgrade-questions-changed="upgradeQuestionsChanged"
            :upgrade-questions="upgradeQuestions"
            :project="activeProject"
            :relay-custom-queries="versions"
            @upgrade="upgradeVersion"
        />

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import URL from '@/components/URL.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'
import Loading from '@/components/loading.vue'
import AnswerBuilder from './AnswerBuilder/AnswerBuilder.vue'

import {formMixin} from '@/mixins/formMixin'
import {onboardingMixin} from '@/mixins/onboardingMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')
const isEqual = require('fast-deep-equal')

export default {
    components: {
        AnswerBuilder,
        Feedback,
        Loading,
        WitTabbedForm,
        UpgradeModal: () => import('./UpgradeModal.vue'),
        WitInputGroup: () => import('@/components/Inputs/WitInputGroup.vue'),
        URL,
    },
    mixins: [formMixin, onboardingMixin, validationMixin],
    validations: {
        reportForm: {
            name: {required},
            variables: {
                valid: function () {
                    return this.answersValid
                },
            },
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    notifications: {
        created: {
            type: VueNotifications.types.success,
            timeout: 8000,
            title: 'Successfully created',
        },
        updated: {
            type: VueNotifications.types.success,
            timeout: 8000,
            title: 'Successfully updated',
        },
    },
    data() {
        return {
            cannotContinue: true,
            formId: 'dataprovider-create-form',
            reportForm: {
                name: null,
                variables: [],
            },
            dataStudioLinkingApi: {},
            currentReportForm: {},
            reportError: {},
            reportCreated: false,
            customQueryId: null,
            questions: [],
            answersValid: false,
            fetched: false,
            version: '',
            versions: [],
            showUpgradeModal: false,
            upgradeQuestions: [],
            dataStudioTemplates: [],
            story: null,
            workflowForm: {
                historicalDateRange: 30,
            },
            answerBuilderLoaded: false,
            skipHistoricalWorkflow: false,
            ongoingSchedule: null,
            historicalWorkflowMax: 365,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'loading/state',
            pipelines: 'pipeline/pipelines',
            activeProject: 'project/active',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        updateDirty() {
            const dirty = filterDirty(this.reportForm, this.currentReportForm)
            return Object.keys(dirty).length > 0
        },
        lastStepSlot() {
            return `step-${this.isDetails || this.skipHistoricalWorkflow ? '2' : '3'}-form`
        },
        steps() {
            return [
                {
                    name: 'Report Settings',
                    invalid: this.$v.$invalid,
                    customToggleInvalid: !this.isDetails && this.reportCreated,
                    inputs: [
                        {name: 'storyblok-step-1'},
                        {
                            name: 'report-version',
                            value: this.version,
                            type: 'text',
                            inputLabel: 'Current report version',
                            readonly: true,
                            vIf: this.isDetails,
                        },
                        {
                            name: 'report-seed',
                            value: this.dataProvider ? this.dataProvider.settings.seed : null,
                            type: 'text',
                            inputLabel: 'Report ID',
                            readonly: true,
                            vIf: this.isDetails,
                        },
                        {
                            name: 'name',
                            value: this.$v.reportForm.name.$model,
                            model: this.$v.reportForm.name,
                            type: 'text',
                            inputLabel: 'Report name',
                            placeholder: 'Name',
                            invalid: 'This field is required',
                            valid: 'Report name is valid',
                        },
                        {
                            name: 'answers',
                        },
                    ],
                },

                this.isDetails || this.skipHistoricalWorkflow
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          customToggleInvalid: !this.isDetails && this.reportCreated,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: this.historicalWorkflowMax,
                              },
                          ],
                      },
                {
                    name: 'Report Template',
                    customToggleInvalid: !this.isDetails && !this.reportCreated,
                },
            ].filter(Boolean)
        },
        latestMinor() {
            if (this.version && this.versions.length) {
                return this.versions.reduce((acc, curr) => {
                    const [major, minor] = curr.split('.')
                    const [accMajor, accMinor] = acc.split('.')
                    if (Number(major) === Number(accMajor) && Number(minor) > Number(accMinor)) {
                        return curr
                    } else return acc
                }, this.version)
            } else return null
        },
        upgradeQuestionsChanged() {
            return !isEqual([...this.questions], [...this.upgradeQuestions])
        },
        dataStudioUrls() {
            const projectId = this.$store.state.project.active.projectId
            const datasetId = `witcloud_${this.$store.state.project.active.id}`.toLowerCase()
            const seed = this.dataProvider ? this.dataProvider.settings.seed : ''

            return this.dataStudioTemplates.map((template) => {
                return template.link
                    .replace(/{{REPORT_NAME}}/g, template.name)
                    .replace(/{{REPORT_ID}}/g, template.id)
                    .replace(/{{PROJECT_ID}}/g, projectId)
                    .replace(/{{DATASET_ID}}/g, datasetId)
                    .replace(/{{SEED}}/g, seed)
            })
        },
        createStepSlot() {
            return `step-${this.skipHistoricalWorkflow ? '1' : '2'}-footer`
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Fetching your report...`)

        if (!this.isDetails) {
            try {
                this.customQueryId = this.$route.params.customQueryId
                await this.fetchVersions()
                this.version = this.versions[this.versions.length - 1]
                await this.fetchQuestions()

                const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                const formSessionId = formSessionMap[this.$route.path].id

                this.$gtm.trackEvent({
                    event: 'create_resource_start',
                    action: 'create',
                    value: formSessionId,
                })

                await new Promise((resolve, reject) => {
                    let intervalCounter = 0

                    this.builderInterval = setInterval(() => {
                        intervalCounter += 1

                        if (this.answerBuilderLoaded) {
                            clearInterval(this.builderInterval)
                            this.builderInterval = null
                            return resolve(true)
                        }

                        if (intervalCounter >= 100) {
                            clearInterval(this.builderInterval)
                            this.builderInterval = null
                            return reject(false)
                        }
                    }, 500)
                })
            } catch (e) {
                this.$errorHandler.report(e, 'Could not prepare data provider form')
                this.$projectRouter.push('/reports/dataproviders/list?createError=true')
            }
        } else {
            await this.initDetails()
        }

        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        onLoad() {
            this.answerBuilderLoaded = true
        },

        loadAnswerBuilder() {
            return new Promise((resolve, reject) => {
                let answerBuilderIntervalIncrement = 0

                this.answerBuilderInterval = setInterval(() => {
                    answerBuilderIntervalIncrement += 1

                    if (this.answerBuilderLoaded) {
                        clearInterval(this.answerBuilderInterval)
                        return resolve(true)
                    }

                    if (answerBuilderIntervalIncrement >= 100) {
                        clearInterval(this.answerBuilderInterval)
                        return reject(false)
                    }
                }, 500)
            })
        },

        async fetchStoryblok() {
            try {
                const spaceRes = await this.$storyblok.get(`cdn/spaces/me`)
                const cacheVersion = spaceRes.data.space.version

                const {
                    data: {story},
                } = await this.$storyblok.get(`cdn/stories/dataproviders/${this.customQueryId}`, {
                    version: ['development', 'local'].includes(process.env.NODE_ENV) ? 'draft' : 'published',
                    cv: cacheVersion,
                })

                this.story = story
            } catch {}
        },

        async initDetails() {
            try {
                await this.fetchDataProvider()
                await this.fetchVersions()
                await this.loadAnswerBuilder()
                await this.fetchQuestions()

                this.reportForm.variables = this.cleanupVariables(this.dataProvider.variables)
                this.currentReportForm = cloneDeep(this.reportForm)

                if (this.latestMinor !== this.version) {
                    await this.fetchUpgradeQuestions()
                }

                this.fetched = true
            } catch (e) {
                this.$errorHandler.report(e, 'Could not fetch details')
                this.$projectRouter.push('/reports/dataproviders/list?createError=true')
            }
        },

        cleanupVariables(variables) {
            return cloneDeep(
                variables.map(({key, value, values, variables}) => {
                    let variable = {key}

                    if (value) {
                        variable.value = value
                    }
                    if (values) {
                        variable.values = values
                            ? values.map((el) => {
                                  if (typeof el === 'object' && el !== null) {
                                      const {ref, ...val} = el
                                      return val
                                  } else return el
                              })
                            : undefined
                    }
                    if (!value && !values) {
                        variable.value = null
                    }
                    if (variables) {
                        variable.variables = variables ? this.cleanupVariables(variables) : undefined
                    }
                    return variable
                })
            )
        },

        async fetchVersions() {
            const {
                data: {data: versions},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/versions/${this.customQueryId}`)

            this.versions = versions
        },

        async fetchTable() {
            const tableRes = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders/${this.dataProvider.id}/tableReference`
            )
            const {projectId, datasetId, tableId} = tableRes.data.data

            const url =
                `https://console.cloud.google.com/bigquery?p=${projectId}&d=${datasetId}&t=${tableId}&page=table`.toLowerCase()
            this.reportTable = {
                destinationTableId: tableId,
                url,
            }

            this.dataStudioLinkingApi = {
                reportName: this.reportForm.name,
                reportId: this.dataStudioTemplateId,
                type: 'TABLE',
                projectId,
                datasetId,
                tableId,
                billingProjectId: projectId,
                isPartitioned: this.isPartitioned,
            }
        },

        async fetchDataProvider() {
            const {id} = this.$route.params

            const dataProviderResponse = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders/${id}`
            )

            this.dataProvider = dataProviderResponse.data.data
            this.reportForm.name = this.dataProvider.name
            this.version = this.dataProvider.customQueryVersionRef.id
            this.customQueryId = this.dataProvider.customQueryRef.id
        },

        async fetchQuestions() {
            const {
                data: {data: versionData},
            } = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/customQuery/versions/${this.customQueryId}/${encodeURIComponent(
                    this.version
                )}`
            )

            this.questions = versionData && versionData.questions ? versionData.questions : []
            this.currentVersion = versionData
            this.dataStudioTemplates =
                versionData && versionData.dataStudioTemplates ? versionData.dataStudioTemplates : ''
            this.skipHistoricalWorkflow =
                versionData && versionData.skipHistoricalWorkflow ? versionData.skipHistoricalWorkflow : false
            this.ongoingSchedule = versionData && versionData.ongoingSchedule ? versionData.ongoingSchedule : null
            this.historicalWorkflowMax =
                versionData && versionData.historicalWorkflowMax ? versionData.historicalWorkflowMax : 365
        },

        async createDataProvider(step) {
            const requestBody = {
                ...this.reportForm,
                projectId: this.activeProject.id,
                customQueryId: this.customQueryId,
                customQueryVersionId: this.version,
                historicalDateRange: !this.skipHistoricalWorkflow ? this.workflowForm.historicalDateRange : undefined,
            }

            this.$store.commit('loading/PROCESSING', `Creating Report...`)
            try {
                const response = await this.axios.post(
                    `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders`,
                    requestBody
                )

                this.reportError = {}

                this.reportCreated = true
                this.dataProvider = response.data.data

                this.resetForm()

                const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                const formSessionId = formSessionMap[this.$route.path].id

                this.$gtm.trackEvent({
                    event: 'create_resource_finish',
                    action: 'finish',
                    value: formSessionId,
                })

                formSessionMap[this.$route.path] = null
                localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                step.nextPage()
            } catch (exception) {
                this.reportError.state = false
                this.reportError.message = exception.response.data.data
                this.$errorHandler.report(exception, 'Could not create form')
            } finally {
                this.$store.commit('loading/PROCESSED')
            }
        },

        async updateDataProvider() {
            const dirty = filterDirty(this.reportForm, this.currentReportForm)
            if (Object.keys(dirty).length > 0) {
                this.reportError = {}
                this.$store.commit('loading/PROCESSING', `Updating...`)
                try {
                    await this.axios.put(
                        `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders/${this.$route.params.id}`,
                        dirty
                    )

                    this.currentReportForm = cloneDeep(this.reportForm)
                    this.$v.$reset()
                } catch (exception) {
                    this.reportError.state = false
                    this.reportError.message = exception.response.data.data
                    this.$errorHandler.report(exception, 'Could not update form')
                } finally {
                    this.$store.commit('loading/PROCESSED')
                }
            } else {
                this.onFinish()
            }
        },

        async fetchUpgradeQuestions() {
            const {
                data: {data: versionData},
            } = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/customQuery/versions/${this.customQueryId}/${encodeURIComponent(
                    this.latestMinor
                )}`
            )
            this.upgradeQuestions = versionData && versionData.questions ? versionData.questions : []
        },

        async upgradeVersion(variables) {
            this.reportError = {}
            this.showUpgradeModal = false
            this.$store.commit('loading/PROCESSING', `Upgrading...`)
            try {
                await this.axios.put(
                    `${process.env.VUE_APP_NODE_API_HOST}/reports/dataProviders/${this.$route.params.id}`,
                    {customQueryVersionId: this.latestMinor, variables: variables ? variables : undefined}
                )

                await this.initDetails()
                this.$v.$reset()
            } catch (exception) {
                this.reportError.state = false
                this.reportError.message = exception.response.data.data
                this.$errorHandler.report(exception, 'Could not upgrade custom query version')
            } finally {
                this.$store.commit('loading/PROCESSED')
            }
        },

        onFinish() {
            return this.isDetails
                ? this.$projectRouter.push('/reports/dataproviders/list')
                : this.$projectRouter.push(
                      `/reports/dataproviders/list?dataProviderCreated=true${
                          this.ongoingSchedule ? `&ongoingSchedule=${encodeURIComponent(this.ongoingSchedule)}` : ''
                      }${this.skipHistoricalWorkflow ? '&historical=false' : ''}`
                  )
        },
    },
}
</script>

<style lang="scss" scoped>
.wit-select {
    z-index: 100;
}
.tableUrl-col {
    margin-top: 5px;
}
.info-photo {
    img {
        height: 150px;
    }
}

.data-provider__data-studio-wrapper {
    .data-provider__data-studio-row {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: min-content auto;
    }
}

.data-provider__url-table {
    margin-bottom: 30px;

    .data-provider__url-table-header {
        font-weight: 600;
        padding-bottom: 15px;

        &.data-provider__url-table-header--data-studio {
            padding-top: 15px;
        }
    }

    td {
        padding-right: 30px;

        > span {
            padding: 0 15px;
        }
    }

    button {
        width: 190px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        img {
            margin-right: 5px;
        }
        i {
            margin-left: 5px;
        }
    }

    a,
    a:hover {
        text-decoration: none;
    }
}
</style>
