import eventBus from '../eventBus'

const isEqual = require('fast-deep-equal')

export const formMixin = {
    data() {
        return {
            fetched: false,
        }
    },
    computed: {
        showReset: {
            get() {
                return this.$store.state.form.showReset
            },

            set(value) {
                this.$store.commit('form/SET_SHOWRESET', value)
            },
        },
        tutorialSteps: {
            get() {
                return this.$store.state.tutorial.steps
            },

            set(value) {
                this.$store.commit('tutorial/SET_STEPS', value)
            },
        },
        tutorialForms: {
            get() {
                return this.$store.state.tutorial.forms
            },
        },
        tutorialForm() {
            if (this.tutorialForms && this.formId) {
                return this.tutorialForms.find(el => el.formId && el.formId === this.formId)
            }
        },
        dirty() {
            return this.$v.$anyDirty
        },
    },
    created() {
        this.showReset = false
        eventBus.$on(`saveForm-${this.formId}`, this.onSaveForm)

        const {tutorialForm} = this
        if (tutorialForm && tutorialForm.data) {
            this.continueTutorialForm(tutorialForm)
        }

        this.checkIfShowModal()
    },
    beforeDestroy() {
        this.saveForm()
        window.removeEventListener('beforeunload', this.saveForm)
        eventBus.$off(`saveForm-${this.formId}`, this.onSaveForm)
    },
    watch: {
        tutorialForms() {
            const {tutorialForm} = this
            if (tutorialForm && tutorialForm.data) {
                this.continueTutorialForm(tutorialForm)
            }
        },

        cannotContinue() {
            this.checkIfShowModal()
        },
    },
    methods: {
        onFormInput(value, input, onInput) {
            input.model.$model = value

            if (onInput) {
                onInput()
            }
        },

        onFormUpdate(value, input, field, onInput) {
            if (input[`${field}Model`]) {
                input[`${field}Model`].$model = value
            }

            if (onInput) {
                onInput()
            }
        },

        async checkIfShowModal() {
            if (this.tutorialSteps.length === 0) {
                window.addEventListener('beforeunload', this.saveForm)

                this.formFound = await this.$store.dispatch('form/find', this.formId)
                const defaultData = {...this._data}

                if (
                    !this.cannotContinue &&
                    this.formFound &&
                    this.formFound.data &&
                    Object.keys(this.formFound.data).length > 0 &&
                    !isEqual({...defaultData}, {...this.formFound.data})
                ) {
                    this.showReset = true
                }
            }

            const {tutorialForm} = this
            if (!this.cannotContinue && tutorialForm && tutorialForm.data) {
                this.continueTutorialForm(tutorialForm)
            }
        },

        saveForm() {
            if (!this.formReset) {
                const form = {
                    formId: this.formId,
                    data: {...this._data},
                    tabIndex: this.$refs.tabbedForm ? this.$refs.tabbedForm.tabIndex : 0,
                }

                this.$store.commit('form/UPSERT', form)
                return form
            }
        },

        onSaveForm(resolve) {
            return resolve(this.saveForm())
        },

        continueForm() {
            Object.keys(this.formFound.data).forEach(key => {
                if (!(this.$options.ignoreContinueValues && this.$options.ignoreContinueValues.includes(key)))
                    this[key] = this.formFound.data[key]
            })
            this.$refs.tabbedForm.tabIndex = this.formFound.tabIndex
        },

        async continueTutorialForm(tutorialForm) {
            await new Promise(resolve => {
                this.interval = setInterval(() => {
                    if (this.$refs.tabbedForm) {
                        clearInterval(this.interval)
                        resolve()
                    }
                }, 100)
            })
            Object.keys(tutorialForm.data.data).forEach(key => (this[key] = tutorialForm.data.data[key]))
            this.$refs.tabbedForm.tabIndex = tutorialForm.data.tabIndex

            eventBus.$emit(`triggerHighlight`)
        },

        resetForm() {
            this.$store.commit('form/CLEAR', this.formId)
            this.formReset = true
        },

        validateRef(ref, step) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            if (step.checked) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
