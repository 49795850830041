<template>
    <b-alert show :variant="blok.variant" class="onboarding-alert">{{ blok.text }}</b-alert>
</template>

<script>
export default {
    props: ['blok'],
}
</script>

<style lang="scss"></style>
