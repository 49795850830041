const isEqual = require('fast-deep-equal')

export const filterDirty = (form, previous) => {
    return Object.keys(form)
        .map(el =>
            !isEqual(form[el], previous[el]) ? [el, form[el]] : null
        )
        .filter(Boolean)
        .reduce((acc, [k, v]) => {
            acc[k] = v
            return acc
        }, {})
}