<template>
    <div class="onboarding-input">
        <b-row>
            <b-col md="4">
                <b-input :type="blok.type" :placeholder="blok.placeholder" v-model="input" @input="emitInput" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'onboardingInput',
    props: ['blok'],
    data() {
        return {
            input: null,
        }
    },
    methods: {
        emitInput() {
            let value = null

            if (this.blok.type === 'number') {
                value = Number(this.input)
            } else {
                value = this.input
            }

            this.$emit('update', {[this.blok.variableName]: value})
        },
    },
}
</script>

<style></style>
