<template>
    <div class="onboarding-collapse">
        <p
            :class="{
                'arrow-right': isActive,
                'arrow-down': !isActive,
                'onboarding-collapse__header': true,
                'pointer-action': true,
            }"
            @click="isActive = !isActive"
        >
            {{ blok.header }}
        </p>
        <b-collapse v-model="isActive" class="onboarding-collapse__body">
            <component
                v-for="blokComponent in blok.body"
                :key="blokComponent._uid"
                :blok="blokComponent"
                :is="blokComponent.component"
                @update="emitInput"
                :substituteVariables="substituteVariables"
                :open="open && isActive"
            ></component>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'onboardingCollapse',
    props: ['blok', 'open', 'substituteVariables'],
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        emitInput(value) {
            this.$emit('update', value)
        },
    },
    created() {
        const previousIsActive = sessionStorage.getItem(`${this.blok._uid}`)

        this.isActive = ['true', 'false'].includes(String(previousIsActive))
            ? previousIsActive === 'true'
            : this.blok.open
    },
    watch: {
        isActive() {
            sessionStorage.setItem(`${this.blok._uid}`, String(this.isActive))
        },
    },
}
</script>

<style lang="scss">
.onboarding-collapse {
    padding: 10px;
    background-color: #e6e6e6;
    border-radius: 5px;

    .onboarding-collapse__header {
        font-weight: 600;
        margin-bottom: 0;
    }

    .onboarding-collapse__body {
        padding: 10px 5px;
    }
}
</style>
