<template>
    <div v-if="question">
        <wit-input-group
            v-if="question.multiple"
            :placeholder="question.title"
            v-model="$v.variable.values.$model"
            @input="emitInput"
            :options="questionOptions"
            :input-label="question.title"
            :input-label-tooltip="question.helpText"
            :model="$v.variable.values"
            :invalid="feedback"
            valid="Value is valid"
            :type="variableType"
        />

        <wit-input-group
            v-else
            :placeholder="question.title"
            v-model="$v.variable.value.$model"
            @input="
                value => {
                    value && question.type === 'NUMBER' ? ($v.variable.value.$model = Number(value)) : null
                    emitInput()
                }
            "
            :options="questionOptions"
            label="label"
            :sub-label="question.type === 'RESOURCE' ? 'subLabel' : undefined"
            :input-label="question.title"
            :input-label-tooltip="question.helpText"
            :model="$v.variable.value"
            :invalid="feedback"
            valid="Value is valid"
            :type="variableType"
        />

        <answer-builder
            :ref="`ab__${variable.key}`"
            v-if="nestedQuestions && nestedQuestions.length > 0"
            v-model="variable.variables"
            :questions="nestedQuestions"
            :valid.sync="answersValid"
            :relay-custom-queries="customQueries"
            :relay-resource-options="resourceOptions"
            :project="project"
            disable-advanced
            @input="emitInput"
            @update:valid="emitUpdate"
        />
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

import {currencies} from '@/shared/currencies'

export default {
    components: {
        Feedback,
        WitInputGroup,
        AnswerBuilder: () => import('./AnswerBuilder'),
    },
    props: {
        value: {
            required: true,
        },
        question: {
            required: true,
        },
        project: {
            required: true,
        },
        valid: {
            type: Boolean,
        },
        customQueries: {
            type: Array,
            default: () => [],
        },
        resourceOptions: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [validationMixin],
    validations: {
        variable: {
            key: {required},
            value: {
                valid: function(_, value) {
                    if (this.question && value) {
                        if (!this.question.multiple) {
                            if (value.value) {
                                if (this.question.type === 'TEXT') {
                                    return value.value && value.value.length > 0
                                }
                                if (this.question.type === 'DATE') {
                                    return value.value && !isNaN(new Date(value.value))
                                }
                                if (this.question.type === 'NUMBER') {
                                    return (
                                        value.value &&
                                        ((this.question.min && value.value >= this.question.min) ||
                                            !this.question.min) &&
                                        ((this.question.max && value.value <= this.question.max) || !this.question.max)
                                    )
                                }
                                if (['RESOURCE', 'LIST'].includes(this.question.type)) {
                                    return Boolean(value.value)
                                }
                                if (this.question.type === 'CURRENCY') {
                                    return value.value && currencies.findIndex(el => el.code === value.value.id) > -1
                                }
                                if (this.question.type === 'TIMEZONE') {
                                    return (
                                        value.value &&
                                        this.questionOptions &&
                                        this.questionOptions.length &&
                                        this.questionOptions.findIndex(el => el.id === value.value.id) > -1
                                    )
                                }
                            } else return false
                        }
                        return true
                    } else return false
                },
            },
            values: {
                valid: function(_, value) {
                    if (this.question && value) {
                        if (['RESOURCE', 'LIST'].includes(this.question.type)) {
                            if (this.question.multiple) {
                                return (
                                    value.values &&
                                    (!this.question.multiple ||
                                        (this.question.multiple &&
                                            ((this.question.min && value.values.length >= this.question.min) ||
                                                !this.question.min) &&
                                            ((this.question.max && value.values.length <= this.question.max) ||
                                                !this.question.max)))
                                )
                            }
                        }
                        return true
                    } else return false
                },
            },
            variables: {
                valid: function() {
                    if (this.question) {
                        return this.question.type === 'LIST' && this.nestedQuestions && this.nestedQuestions.length > 0
                            ? this.answersValid
                            : true
                    } else return false
                },
            },
        },
    },
    data() {
        return {
            variable: {
                key: '',
                value: '',
                values: [],
                variables: [],
            },
            answersValid: false,
        }
    },
    computed: {
        variableType() {
            if (this.question.type === 'NUMBER') return 'number'
            if (this.question.type === 'TEXT') return 'text'
            if (this.question.type === 'DATE') return 'date'
            if (this.question.type === 'LIST' && !this.question.multiple) return 'select'
            if (this.question.type === 'RESOURCE' && !this.question.multiple) return 'select'
            if (this.question.type === 'RESOURCE' && !this.question.multiple) return 'select'
            if (this.question.type === 'CURRENCY') return 'select'
            if (this.question.type === 'TIMEZONE') return 'select'
            if (this.question.multiple) return 'treeselect'
        },
        questionOptions() {
            if (this.question.type === 'LIST') {
                return this.question.choices.map(el => ({id: el.label, ...el}))
            }

            if (this.question.type === 'CURRENCY') {
                return currencies.map(el => ({id: el.code, label: `${el.code} (${el.name})`}))
            }

            if (this.question.type === 'TIMEZONE') {
                return process.env.VUE_APP_TIMEZONES.split(',').map(el => ({id: el, label: el}))
            }

            if (this.question.type === 'RESOURCE') {
                const options = []

                if (this.question.filterBy === 'Type') {
                    options.push(...this.resourceOptions.filter(el => this.question.filter.includes(el.type)))
                } else if (this.question.filterBy === 'Subtype') {
                    options.push(
                        ...this.resourceOptions.filter(el => this.question.filter.includes(`${el.type}/${el.subtype}`))
                    )
                } else if (this.question.filterBy === 'Custom Query Name') {
                    options.push(
                        ...this.resourceOptions.filter(resource => {
                            if (resource.customQueryRef) {
                                const customQuery = this.customQueries.find(el => resource.customQueryRef.id === el.id)

                                if (customQuery) {
                                    try {
                                        const flags = this.question.filter[0].replace(/.*\/([gimy]*)$/, '$1')
                                        const pattern = this.question.filter[0].replace(
                                            new RegExp('^/(.*?)/' + flags + '$'),
                                            '$1'
                                        )

                                        return new RegExp(pattern, flags).test(customQuery.name)
                                    } catch {
                                        return false
                                    }
                                }
                            } else return false
                        })
                    )
                }

                return options.map(el => ({id: el.id, label: el.name, subLabel: el.subtype}))
            }

            return []
        },

        nestedQuestions() {
            if (this.question.type === 'LIST') {
                if (this.question.multiple) {
                    return this.variable.values
                        .map(el => {
                            const answer = this.questionOptions.find(question => question.id === el)
                            return answer && answer.nestedQuestions ? answer.questions : []
                        })
                        .flat()
                } else {
                    if (this.variable.value) {
                        const answer = this.questionOptions.find(question => question.id === this.variable.value.label)
                        return answer && answer.nestedQuestions ? answer.questions : []
                    }
                }
            }

            return []
        },

        feedback() {
            let feedback = `This field is required`

            if (this.question.type === 'NUMBER') {
                if (this.question.min && this.question.max) {
                    feedback = `${feedback}, must be greater than or equal to ${this.question.min} and smaller than or equal to ${this.question.max}`
                }

                if (this.question.min && !this.question.max) {
                    feedback = `${feedback} and must be greater than or equal to ${this.question.min}`
                }

                if (!this.question.min && this.question.max) {
                    feedback = `${feedback} and must be smaller than or equal to ${this.question.max}`
                }
            }

            if (this.question.multiple) {
                if (this.question.min && this.question.max) {
                    feedback = `${feedback}, you must select between ${this.question.min} and ${this.question.max} values`
                }

                if (this.question.min && !this.question.max) {
                    feedback = `${feedback}, you must select at least ${this.question.min} values`
                }

                if (!this.question.min && this.question.max) {
                    feedback = `${feedback}, you must select less than or equal to ${this.question.max} values`
                }
            }

            return feedback
        },
    },
    watch: {
        'variable.values': {
            handler(newArray, oldArray) {
                if (newArray.length < oldArray.length) {
                    const idx = oldArray.findIndex(el => !newArray.includes(el))
                    this.variable.variables.splice(idx, 1)
                }
            },
            deep: true,
        },
    },
    created() {
        this.variable = {...this.variable, ...this.value}
        this.emitInput()
    },
    methods: {
        emitInput() {
            let {value, values} = this.variable

            if (this.question.multiple && this.question.type === 'LIST') {
                values = values.map(el => this.question.choices.find(choice => choice.label === el))
            }

            this.$emit('input', {
                ...this.question,
                ...this.variable,
                nestedQuestions: this.nestedQuestions,
                value,
                values,
            })
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },
    },
}
</script>

<style></style>
