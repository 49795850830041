<template>
    <div class="onboarding-codeblock__wrapper">
        <div
            class="onboarding-codeblock"
            :id="`onboarding-codeblock-${blok._uid}`"
            v-bind:style="style"
            v-bind:class="{'onboarding-codeblock__uncollapsed': show}"
        >
            <vue-code-highlight>{{ codeBeautified }}</vue-code-highlight>
            <div class="onboarding-codeblock__show" v-if="blok.collapse">
                <div class="onboarding-codeblock__show-text pointer-action" @click="() => (show = !show)">
                    {{ show ? 'Hide' : 'Show more' }}
                </div>
            </div>
        </div>
        <div class="onboarding-codeblock__button onboarding-codeblock__copy pointer-action" @click="copyToClipboard">
            <i class="fa fa-copy" /> Copy
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import {component as VueCodeHighlight} from 'vue-code-highlight'
import 'vue-code-highlight/themes/prism-tomorrow.css'

Vue.use(VueClipboard)

export default {
    props: ['blok', 'open', 'substituteVariables'],
    components: {
        VueCodeHighlight,
    },
    data() {
        return {
            show: false,
            height: 0,
        }
    },
    computed: {
        codeBeautified() {
            const code = this.substituteVariables(this.blok.code)

            if (this.blok.format) {
                if (this.blok.language === 'js') {
                    const beautify = require('js-beautify').js
                    return beautify(code)
                }
                if (this.blok.language === 'sql') {
                    const sqlFormatter = require('sql-formatter')
                    return sqlFormatter.format(code)
                }
            } else {
                return code
            }
        },

        style() {
            let style = {}

            if (this.blok.collapse) {
                style.maxHeight = this.show ? `calc(${this.height}px + 3em)` : '100px'
            }

            return style
        },
    },
    watch: {
        open() {
            if (this.open) {
                const div = document.querySelector(`#onboarding-codeblock-${this.blok._uid} > div`)
                this.height = div.clientHeight
            }
        },
    },
    mounted() {
        if (this.open) {
            const div = document.querySelector(`#onboarding-codeblock-${this.blok._uid} > div`)
            this.height = div.clientHeight
        }
    },
    methods: {
        copyToClipboard() {
            this.$copyText(this.codeBeautified)
        },
    },
}
</script>

<style lang="scss">
.onboarding-codeblock__wrapper {
    position: relative;

    .onboarding-codeblock {
        display: flex;
        align-items: flex-start;
        width: 100%;
        overflow: hidden;
        transition: max-height 0.2s linear;

        > div {
            width: 100%;
        }

        .onboarding-codeblock__show {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 80px;
            background: linear-gradient(to bottom, transparent 0%, #2d2d2d 100%);
            display: flex;
            align-items: flex-end;

            .onboarding-codeblock__show-text {
                text-align: center;
                color: white;
                width: 100%;
                height: 25px;
            }
        }

        .onboarding-codeblock__show:hover {
            .onboarding-codeblock__show-text {
                font-weight: 600;
            }
        }
    }

    .onboarding-codeblock__uncollapsed {
        pre[class*='language-'] {
            padding-bottom: 3em;
        }

        .onboarding-codeblock__show {
            height: 40px;
            margin-bottom: 5px;
        }
    }

    .onboarding-codeblock__button {
        color: white;
        padding: 0.25rem 0.45rem;
        border: 1px solid #ffc107;
        border-radius: 25px;
        margin: 0.25rem 0.45rem;
        font-weight: 600;
        width: 73px;
    }

    .onboarding-codeblock__button:hover {
        background-color: #ffc107;
    }

    .onboarding-codeblock__copy {
        position: absolute;
        top: 10px;
        right: 5px;
    }
}
</style>
