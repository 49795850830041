<template>
    <v-runtime-template class="onboarding-richtext" :template="substituteVariables(richtext)" />
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'

export default {
    name: 'onboardingRichtext',
    props: ['blok', 'substituteVariables', 'open'],
    components: {
        VRuntimeTemplate,
    },
    beforeCreate() {
        this.$storyblok.setComponentResolver((component, blok) => {
            return `<component :blok='${JSON.stringify(blok)}'
            is="${component}"
            @update="onUpdate"
            :substituteVariables="substituteVariables"
            :open="open"></component>`
        })
    },
    computed: {
        richtext() {
            return '<div>' + this.$storyblok.richTextResolver.render(this.blok.value) + '</div>'
        },
    },
    methods: {
        onUpdate(value) {
            this.$emit('update', value)
        },
    },
}
</script>

<style lang="scss">
.onboarding-richtext {
    max-width: 100%;

    img {
        max-width: 100%;
        object-fit: contain;
    }
}
</style>
