<template>
    <div class="wit-input-group">
        <div class="wit-input-group__label">
            {{ inputLabel }}
            <span v-if="inputLabelTooltip" v-b-tooltip.hover :title="inputLabelTooltip">
                <i class="fa fa-question-circle" />
            </span>
        </div>
        <slot>
            <b-row v-if="inputType && !inputType.inputRow">
                <b-col :md="inputCol">
                    <wit-input
                        v-if="requiredInput === 'wit-input'"
                        v-model="internalValue"
                        :id="id"
                        :type="type"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :readonly="readonly"
                        :max="max"
                        :min="min"
                        @input="emitInput"
                    ></wit-input>
                    <password
                        v-if="requiredInput === 'password'"
                        v-model="internalValue"
                        :id="id"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :show-reset="showReset"
                        :validation-messages="validationMessages"
                        :model="model"
                        @input="emitInput"
                    ></password>
                    <wit-file
                        v-if="requiredInput === 'wit-file'"
                        v-model="internalValue"
                        :id="id"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        @input="emitInput"
                    ></wit-file>
                    <wit-textarea
                        v-if="requiredInput === 'wit-textarea'"
                        v-model="internalValue"
                        :id="id"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :readonly="readonly"
                        :rows="rows"
                        :max-rows="maxRows"
                        @input="emitInput"
                    ></wit-textarea>
                    <wit-markdown
                        v-if="requiredInput === 'wit-markdown'"
                        v-model="internalValue"
                        :id="id"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        @input="emitInput"
                    ></wit-markdown>
                    <wit-checkbox
                        v-if="requiredInput === 'wit-checkbox'"
                        v-model="internalValue"
                        :id="id"
                        :disabled="disabled"
                        :checked="checked"
                        :unchecked="unchecked"
                        @input="emitInput"
                    ></wit-checkbox>
                    <wit-checkbox-group
                        v-if="requiredInput === 'wit-checkbox-group'"
                        v-model="internalValue"
                        :id="id"
                        :options="options"
                        :disabled="disabled"
                        @input="emitInput"
                    ></wit-checkbox-group>
                    <wit-radio
                        v-if="requiredInput === 'wit-radio'"
                        v-model="internalValue"
                        :id="id"
                        :disabled="disabled"
                        :options="options"
                        @input="emitInput"
                    ></wit-radio>
                    <wit-select
                        v-else-if="requiredInput === 'wit-select'"
                        v-model="internalValue"
                        :id="id"
                        :label="label"
                        :customLabel="customLabel"
                        :placeholder="placeholder"
                        :options="options"
                        :notReady="notReady"
                        :notReadyMessage="notReadyMessage"
                        :disabled="disabled"
                        :reduce="reduce"
                        :allowEmpty="allowEmpty"
                        :sub-label="subLabel"
                        :subtract-sub-label="subtractSubLabel"
                        :track-by="trackBy"
                        :preselectFirst="preselectFirst"
                        @input="emitInput"
                    ></wit-select>
                    <wit-treeselect
                        v-else-if="requiredInput === 'wit-treeselect'"
                        v-model="internalValue"
                        :id="id"
                        :placeholder="placeholder"
                        :options="options"
                        :disabled="disabled"
                        :multiple="multiple"
                        :value-format="valueFormat"
                        :value-consists-of="valueConsistsOf"
                        :limit="limit"
                        :default-expand-level="defaultExpandLevel"
                        :before-clear-all="beforeClearAll"
                        :normalizer="normalizer"
                        @input="emitInput"
                    ></wit-treeselect>
                    <cron-generator
                        v-else-if="requiredInput === 'cron-generator'"
                        v-model="internalValue"
                        :id="id"
                        :visible-hourly="visibleHourly"
                        :visible-daily="visibleDaily"
                        :visible-weekly="visibleWeekly"
                        :visible-monthly="visibleMonthly"
                        :visible-every-x-minutes="visibleEveryXMinutes"
                        @input="emitInput"
                    ></cron-generator>
                    <copy-text v-else-if="requiredInput === 'copy-text'" v-model="internalValue" :id="id"></copy-text>
                    <URL v-else-if="requiredInput === 'URL'" v-model="internalValue" :id="id"></URL>
                    <slot name="feedback">
                        <Feedback v-if="model" :state="inputState" :invalid="invalid" :valid="valid"></Feedback>
                    </slot>
                </b-col>
                <b-col>
                    <slot name="right-col" />
                </b-col>
            </b-row>
            <component
                v-else
                :is="requiredInput"
                v-model="internalValue"
                :projectId="projectId"
                :disabled="disabled"
                :placeholder="placeholder"
                :id="id"
                :step="step"
                :shopUrl.sync="internalShopUrl"
                :scope="scope"
                :requestedScopes="requestedScopes"
                :preselectFirst="preselectFirst"
                :max="max"
                :min="min"
                @input="emitInput"
            >
                <template v-if="['range'].includes(requiredInput)" v-slot:feedback>
                    <slot name="feedback">
                        <Feedback v-if="model" :state="inputState" :invalid="invalid" :valid="valid"></Feedback>
                    </slot>
                </template>
            </component>
        </slot>
    </div>
</template>

<script>
export default {
    components: {
        CopyText: () => import('../CopyText.vue'),
        CronGenerator: () => import('../CronGenerator.vue'),
        Feedback: () => import('../Feedback.vue'),
        Password: () => import('./Password.vue'),
        SelectAllegroToken: () => import('../Tokens/Selects/SelectAllegroToken.vue'),
        SelectAwinToken: () => import('../Tokens/Selects/SelectAwinToken'),
        SelectConvertiserToken: () => import('../Tokens/Selects/SelectConvertiserToken'),
        SelectBaselinkerToken: () => import('../Tokens/Selects/SelectBaselinkerToken.vue'),
        SelectShopifyToken: () => import('../Tokens/Selects/SelectShopifyToken.vue'),
        SelectCriteoToken: () => import('../Tokens/Selects/SelectCriteoToken.vue'),
        SelectFacebookToken: () => import('../Tokens/Selects/SelectFacebookToken.vue'),
        SelectGoogleToken: () => import('../Tokens/Selects/SelectGoogleToken.vue'),
        SelectHubSpotToken: () => import('../Tokens/Selects/SelectHubSpotToken.vue'),
        SelectBingAdsToken: () => import('../Tokens/Selects/SelectBingAdsToken.vue'),
        SelectTikTokToken: () => import('../Tokens/Selects/SelectTikTokToken.vue'),
        SelectMagentoToken: () => import('../Tokens/Selects/SelectMagentoToken.vue'),
        SelectPrestaShopToken: () => import('../Tokens/Selects/SelectPrestaShopToken.vue'),
        SelectIdoSellToken: () => import('../Tokens/Selects/SelectIdoSellToken.vue'),
        SelectWooCommerceToken: () => import('../Tokens/Selects/SelectWooCommerceToken.vue'),
        SelectOptimiseToken: () => import('../Tokens/Selects/SelectOptimiseToken.vue'),
        SelectRTBToken: () => import('../Tokens/Selects/SelectRTBToken.vue'),
        SelectDomodiToken: () => import('../Tokens/Selects/SelectDomodiToken.vue'),
        SelectCeneoToken: () => import('../Tokens/Selects/SelectCeneoToken.vue'),
        SelectShoperToken: () => import('../Tokens/Selects/SelectShoperToken.vue'),
        SelectTradedoublerToken: () => import('../Tokens/Selects/SelectTradedoublerToken.vue'),
        URL: () => import('../URL.vue'),
        WitCheckbox: () => import('./WitCheckbox.vue'),
        WitCheckboxGroup: () => import('./WitCheckboxGroup.vue'),
        WitFile: () => import('./WitFile.vue'),
        WitInput: () => import('./WitInput.vue'),
        WitMarkdown: () => import('./WitMarkdown.vue'),
        WitRadio: () => import('./WitRadio.vue'),
        WitRange: () => import('./WitRange.vue'),
        WitSelect: () => import('./WitSelect.vue'),
        WitTextarea: () => import('./WitTextarea.vue'),
        WitTreeselect: () => import('./WitTreeselect.vue'),
    },
    props: {
        value: {
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        id: {
            type: String,
        },
        invalid: {
            type: String,
        },
        valid: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        max: {
            type: Number,
        },
        min: {
            type: Number,
        },
        options: {
            type: Array,
        },
        limit: {
            type: Number,
        },
        valueFormat: {
            type: String,
        },
        valueConsistsOf: {
            type: String,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        inputLabel: {
            type: String,
        },
        description: {
            type: String,
        },
        label: {
            type: String,
            default: undefined,
        },
        customLabel: {
            type: Function,
        },
        preselectFirst: {
            type: Boolean,
        },
        subLabel: {
            type: String,
        },
        subtractSubLabel: {
            type: Boolean,
        },
        reduce: {
            type: Function,
            default: (option) => option,
        },
        size: {
            type: String,
            default: 'md',
            // 'lg', 'md', 'sm'
        },
        inline: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        calculatePosition: {
            type: Function,
        },
        filterBy: {
            type: Function,
        },
        notReady: {
            type: Boolean,
        },
        notReadyMessage: {
            type: String,
        },
        checked: {
            type: String,
        },
        unchecked: {
            type: String,
        },
        model: {
            type: Object,
        },
        valid: {
            type: String,
        },
        invalid: {
            type: String,
        },
        step: {
            type: Object,
        },
        projectId: {
            type: String,
        },
        onInput: {
            type: Function,
        },
        shopUrl: {
            type: String,
        },
        labelCol: {
            type: [String, Number],
            default: 2,
        },
        inputCol: {
            type: [String, Number],
            default: 8,
        },
        inputLabelTooltip: {
            type: String,
        },
        rows: {
            type: [String, Number],
        },
        maxRows: {
            type: [String, Number],
        },
        defaultExpandLevel: {
            type: [String, Number],
        },
        beforeClearAll: {
            type: Function,
        },
        normalizer: {
            type: Function,
        },
        scope: {
            type: Array,
        },
        requestedScopes: {
            type: Array,
        },
        trackBy: {
            type: String,
        },
        showReset: {
            type: Boolean,
        },
        validationMessages: {
            type: Object,
        },
        visibleHourly: {
            default: true,
        },
        visibleDaily: {
            default: true,
        },
        visibleWeekly: {
            default: true,
        },
        visibleMonthly: {
            default: true,
        },
        visibleEveryXMinutes: {
            default: false,
        },
    },
    data() {
        return {
            internalValue: null,
            inputTypes: [
                {
                    type: 'text',
                    input: 'wit-input',
                },
                {
                    type: 'textarea',
                    input: 'wit-textarea',
                },
                {
                    type: 'number',
                    input: 'wit-input',
                },
                {
                    type: 'email',
                    input: 'wit-input',
                },
                {
                    type: 'date',
                    input: 'wit-input',
                },
                {
                    type: 'markdown',
                    input: 'wit-markdown',
                },
                {
                    type: 'checkbox',
                    input: 'wit-checkbox',
                },
                {
                    type: 'checkbox-group',
                    input: 'wit-checkbox-group',
                },
                {
                    type: 'radio',
                    input: 'wit-radio',
                },
                {
                    type: 'password',
                    input: 'password',
                },
                {
                    type: 'select',
                    input: 'wit-select',
                },
                {
                    type: 'treeselect',
                    input: 'wit-treeselect',
                },
                {
                    type: 'copy',
                    input: 'copy-text',
                },
                {
                    type: 'awin-token',
                    input: 'select-awin-token',
                    inputRow: true,
                },
                {
                    type: 'convertiser-token',
                    input: 'select-convertiser-token',
                    inputRow: true,
                },
                {
                    type: 'baselinker-token',
                    input: 'select-baselinker-token',
                    inputRow: true,
                },
                {
                    type: 'prestashop-token',
                    input: 'select-presta-shop-token',
                    inputRow: true,
                },
                {
                    type: 'idosell-token',
                    input: 'select-ido-sell-token',
                    inputRow: true,
                },
                {
                    type: 'woocommerce-token',
                    input: 'select-woo-commerce-token',
                    inputRow: true,
                },
                {
                    type: 'shopify-token',
                    input: 'select-shopify-token',
                    inputRow: true,
                },
                {
                    type: 'criteo-token',
                    input: 'select-criteo-token',
                    inputRow: true,
                },
                {
                    type: 'facebook-token',
                    input: 'select-facebook-token',
                    inputRow: true,
                },
                {
                    type: 'google-token',
                    input: 'select-google-token',
                    inputRow: true,
                },
                {
                    type: 'hubspot-token',
                    input: 'select-hub-spot-token',
                    inputRow: true,
                },
                {
                    type: 'tiktok-token',
                    input: 'select-tik-tok-token',
                    inputRow: true,
                },
                {
                    type: 'bing-ads-token',
                    input: 'select-bing-ads-token',
                    inputRow: true,
                },
                {
                    type: 'magento-token',
                    input: 'select-magento-token',
                    inputRow: true,
                },
                {
                    type: 'optimise-token',
                    input: 'select-optimise-token',
                    inputRow: true,
                },
                {
                    type: 'tradedoubler-token',
                    input: 'select-tradedoubler-token',
                    inputRow: true,
                },

                {
                    type: 'shoper-token',
                    input: 'select-shoper-token',
                    inputRow: true,
                },
                {
                    type: 'rtb-token',
                    input: 'SelectRTBToken',
                    inputRow: true,
                },
                {
                    type: 'domodi-token',
                    input: 'SelectDomodiToken',
                    inputRow: true,
                },
                {
                    type: 'ceneo-token',
                    input: 'SelectCeneoToken',
                    inputRow: true,
                },
                {
                    type: 'allegro-token',
                    input: 'select-allegro-token',
                    inputRow: true,
                },
                {
                    type: 'url',
                    input: 'URL',
                },
                {
                    type: 'file',
                    input: 'wit-file',
                },
                {
                    type: 'range',
                    input: 'wit-range',
                    inputRow: true,
                },
                {
                    type: 'cron',
                    input: 'cron-generator',
                },
            ],
            internalShopUrl: '',
        }
    },
    computed: {
        inputType() {
            const inputFound = this.inputTypes.find((el) => el.type === this.type)
            return inputFound
        },

        requiredInput() {
            return this.inputType ? this.inputType.input : null
        },

        inputState() {
            if (this.model) {
                if (this.step && this.step.checked) return !this.model.$invalid
                return this.model.$dirty ? !this.model.$invalid : null
            } else {
                return null
            }
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },

        shopUrl() {
            if (this.internalShopUrl !== this.shopUrl) {
                this.internalShopUrl = this.shopUrl
            }
        },

        internalShopUrl() {
            this.emitUpdate()
        },
    },
    created() {
        this.internalValue = this.value
        this.internalShopUrl = this.shopUrl
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },

        emitUpdate() {
            this.$emit('update:shopUrl', this.internalShopUrl)
        },
    },
}
</script>

<style lang="scss">
.wit-input-group {
    &__label {
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 10px;
        color: #000;
    }

    margin-bottom: 30px;
}
</style>
